import Vue from "vue";
import Options from "vue-class-component";
import HelloWorldComponent from "@/components/HelloWorld/index.vue";
import AdminLayout from "@/layouts/Admin/index.vue";
import ClientService from "@/services/ClientService";
import ClientUserService from "@/services/ClientUserService";

@Options({
  components: {
    AdminLayout,
  },
})
export default class Dashboard extends Vue {
  dasboardTileData: any = {};
  search=""


  async created() {
    this.dasboardTileData = await ClientService.getClientDashboard();
    console.log(this.dasboardTileData);

  }


}
