import { IClient, IResponse } from "@/models/IClient";
import { IClientSave } from "@/models/IClientSave";
import { IPackage } from "@/models/IPackage";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { IUser } from "@/models/IUser";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;

class ClientService {
  client: IClient[] = [];
  package: IPackage[] = [];
  data: any;

  getListofClient(pageIndex: number, search: string = ""): Result {
    var URL = API_URL + "admin/getallclient/" + pageIndex;

    if (search.length > 0) {
      URL = API_URL + "admin/getallclient/" + pageIndex + "?search=" + search;
    }

    this.data = axios
      .get<IPageResponse>(URL, { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.data;
  }


  getAllPackage(): IPackage[] {
    this.data = axios
      .get<IResponse>(API_URL + "admin/GetAllPackage", {
        headers: auhtHeader(),
      })
      .then((response) => {
        this.package = response.data.result;
        return this.package;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.data;
  }

  saveClient(client: IClientSave) : Promise<IResponse>{
   this.data = axios
      .post<IResponse>(API_URL + "admin/saveclient", client, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

      return this.data;
  }



  getClienByClientId(clientId: number) : Promise<IClientSave>{
      this.data = axios.get<IResponse>(API_URL + "admin/getclientbyclientId?clientId=" + clientId,
      {headers: auhtHeader()})
      .then((response) => {
        return response.data.result
      })
      .catch((error) => {
        console.log(error);
      })

      return this.data;
  }


  getClientDashboard() : Promise<any>{
    this.data = axios.get<IResponse>(API_URL + "ClientUser/getdashboard",
    {headers: auhtHeader()})
    .then((response) => {
      return response.data.result
    })
    .catch((error) => {
      console.log(error);
    })

    return this.data;
}


}

export default new ClientService();
